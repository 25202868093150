<script setup>
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import CultureReportCover from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureReportCover.vue";
import CultureReportPredominanteCulture from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureReportPredominanteCulture.vue";

import CandidateReportBack from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CandidateReportBack.vue";

import CultureReportKPIAdjustment from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureReportKPIAdjustment.vue";
import DictionaryForCultureReport from "@/globals/DictionaryForCultureReport";

const AWS = require("aws-sdk");
const store = useStore();
const pdfUrl = ref("");
const showReport = ref(true);
const $loading = useLoading({});
let loadingLayer = null;

const route = useRoute();

const CultureReportData = reactive({
  name: "-",
  token: "-",
  // Como el empleado percibe la organización
  predominantCulturesOrganizacion: [
    { name: "Predominance Values", data: [10, 20, 30, 40, 50, 60] },
  ],
  // Como el empleado se percibe a sí mismo
  predominantCulturesIndviduo: [
    { name: "Predominance Values", data: [10, 20, 30, 40, 50, 60] },
  ],
  // Como desea el empleado que fuese la organización
  predominantCulturesDeseada: [
    { name: "Predominance Values", data: [10, 20, 30, 40, 50, 60] },
  ],
  //
  cultureVSTeamMindset: [
    {
      name: "Predominance Organization Values",
      data: [10, 20, 30, 40, 50, 60],
    },
    { name: "Predominance Team Values", data: [60, 50, 40, 30, 20, 10] },
  ],
  predominance: [],
  ArqTitle: [],
  mediaValuesOrg: [],
  mediaValuesOrgAndScore: [],
  mediaValuesIndAndScore: [],
  mediaValuesDesAndScore: [],
});

const dataLoaded = ref(false); // Indicator for data loading

const routeParams = route.params;
const currentLanguage = computed(() => store.getters.currentLanguage);

const setLanguageFromRoute = () => {
  const language = route.params.language || "es"; // Default to 'es' if no language param
  store.commit("SET_LANGUAGE", language);
};

// Watch for changes in route params and update store
watch(
  () => routeParams.language,
  (newLanguage) => {
    store.commit("SET_LANGUAGE", newLanguage);
  }
);

const getDictionaryValue = (key) => {
  return computed(() => DictionaryForCultureReport[currentLanguage.value][key]);
};

const [CultureOF] = [0].map(getDictionaryValue);

const fetchCultureReportData = async (projectToken) => {
  try {
    const { data } = await ProfileEvaluationService.getCultureReportData(
      projectToken
    );
    console.log("Data received: ", data);

    if (data) {
      Object.assign(CultureReportData, {
        name: data.cli_id || CultureOF,
        token: data.cliente || "-",
        predominantCulturesOrganizacion: [
          { name: "Cultura organización", data: data.pAorg || [] },
        ],
        predominantCulturesIndviduo: [
          { name: "Cultura individuo", data: data.pAind || [] },
        ],
        predominantCulturesDeseada: [
          { name: "Cultura deseada", data: data.pAdes || [] },
        ],
        cultureVSTeamMindset: [
          { name: "Cultura organización", data: data.pAorg || [] },
          { name: "Cultura deseada", data: data.pAdes || [] },
        ],
        ArqTitle: data.arquetipos,
        predominanceOrganizacion: processPredominance(
          data.arquetipos,
          data.pAorg
        ),
        predominanceIndviduo: processPredominance(data.arquetipos, data.pAind),
        predominanceDeseada: processPredominance(data.arquetipos, data.pAdes),
        valuesTitle: data.valores,
        mediaValuesOrg: data.mVorg,
        mediaValuesOrgAndScore: processMediaValues(data.valores, data.mVorg),
        mediaValuesIndAndScore: processMediaValues(data.valores, data.mVind),
        mediaValuesDesAndScore: processMediaValues(data.valores, data.mVdes),
      });
      console.log(
        "Processed mediaValuesOrgAndScore: ",
        JSON.stringify(CultureReportData.mediaValuesOrgAndScore)
      );
    }
    window.dataFetched = true;
    dataLoaded.value = true; // Set data loaded to true
  } catch (error) {
    console.error("Error fetching report data:", error);
    window.dataFetched = false;
  }
};

// Configuración de AWS y creación del cliente S3
AWS.config.update({
  accessKeyId: "AKIAZO2KPRALOH7S2EK3",
  secretAccessKey: "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",
  region: "eu-south-2",
});

const s3 = new AWS.S3(); // Crear instancia del cliente S3

// Lógica para determinar los colores basados en el tipo de cultura
const assignColors = (cultureType) => {
  if (cultureType === "Cultura organización") return ["#33B0B3", "#33B0B3"];
  else if (cultureType === "Cultura individuo") return ["#3371B3", "#3384B3"];
  else if (cultureType === "Cultura deseada") return ["#D1D12D", "#B0B02F"];
  else return ["#cccccc", "#999999"]; // Default colors
};

// Computed para los diferentes tipos de culturas
const organizationColors = computed(() => assignColors("Cultura organización"));
const individualColors = computed(() => assignColors("Cultura individuo"));
const desiredColors = computed(() => assignColors("Cultura deseada"));

// Función para generar el PDF y subirlo a S3
const generatePDF = async () => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 2200));

    const reportElement = document.querySelector("#candidate-report-CULTURAL");
    if (reportElement) {
      const canvas = await html2canvas(reportElement, {
        scale: window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio,
        width: 1124.5, // Cambiado a la nueva anchura
        windowWidth: 1124.5, // Cambiado a la nueva anchura
        logging: true,
      });

      const pdf = new jsPDF({
        orientation: "landscape", // Cambiado a landscape
        unit: "px",
        format: "a4",
        compress: true,
        putOnlyUsedFonts: true,
        hotfixes: ["px_scaling"],
      });

      const pageHeight = pdf.internal.pageSize.getHeight();
      let canvasHeight = canvas.height;
      let position = 0;

      while (canvasHeight > 0) {
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0,
          position,
          canvas.width,
          canvas.height
        );
        canvasHeight -= pageHeight;
        position -= pageHeight;
        if (canvasHeight > 0) pdf.addPage();
      }

      // Convertir el PDF a un Blob y luego a un Buffer para subir a S3
      const pdfBlob = pdf.output("blob");
      const arrayBuffer = await pdfBlob.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);

      const bucketName = "cfs-report";
      const s3Key = `CultureEvaluation/${CultureReportData.token}.pdf`;
      const s3Url = await uploadPDFToS3(buffer, bucketName, s3Key);

      pdfUrl.value = s3Url;
      showReport.value = false;
      console.log("PDF URL:", pdfUrl.value);
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

// Función para subir el PDF a S3
async function uploadPDFToS3(buffer, bucketName, key) {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: buffer,
    ContentType: "application/pdf",
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading to S3:", err);
        reject(err);
      } else {
        console.log("Upload Success", data);
        resolve(data.Location);
      }
    });
  });
}

const processPredominance = (arquetipos, porcentajeArqutipos) => {
  if (!arquetipos) {
    return [];
  }
  return arquetipos
    .map((title, index) => ({
      title,
      value: porcentajeArqutipos[index],
    }))
    .sort((a, b) => b.value - a.value);
};

const processMediaValues = (valores, valorMedio) => {
  if (!valores) {
    return [];
  }
  return valores
    .map((title, index) => ({
      title,
      value: valorMedio[index],
    }))
    .sort((a, b) => b.value - a.value);
};

onMounted(async () => {
  setLanguageFromRoute();
  loadingLayer = $loading.show({ color: "#34495e", opacity: 0.7 }); // Change opacity to number
  const { projectToken } = route.params;
  console.log("Project Token:", projectToken);
  await fetchCultureReportData(projectToken);
  await nextTick();
  await generatePDF();
  loadingLayer.hide();
});

console.log("en el view");
console.log(CultureReportData);
</script>

<template>
  <div>
    <div
      v-if="showReport"
      id="candidate-report-CULTURAL"
      class="report-tpl-container2"
    >
      <CultureReportCover :CultureReportData="CultureReportData" />

      <!-- Reporte de Cultura de Organización -->
      <CultureReportPredominanteCulture
        :nameTitle="CultureReportData.name"
        :predominantCultures="CultureReportData.predominantCulturesOrganizacion"
        :predominance="CultureReportData.predominanceOrganizacion"
        :mediaValues="CultureReportData.mediaValuesOrgAndScore"
        :colors="organizationColors"
      />

      <!-- Reporte de Cultura del Individuo -->
      <CultureReportPredominanteCulture
        :nameTitle="CultureReportData.name"
        :predominantCultures="CultureReportData.predominantCulturesIndviduo"
        :predominance="CultureReportData.predominanceIndviduo"
        :mediaValues="CultureReportData.mediaValuesIndAndScore"
        :colors="individualColors"
      />

      <!-- Reporte de Cultura Deseada -->
      <CultureReportPredominanteCulture
        :nameTitle="CultureReportData.name"
        :predominantCultures="CultureReportData.predominantCulturesDeseada"
        :predominance="CultureReportData.predominanceDeseada"
        :mediaValues="CultureReportData.mediaValuesDesAndScore"
        :colors="desiredColors"
      />

      <CultureReportKPIAdjustment
        :nameTitle="CultureReportData.name"
        :predominantCultures="CultureReportData.cultureVSTeamMindset"
        :predominance="CultureReportData.predominanceDeseada"
        :mediaValues="CultureReportData.mediaValuesDesAndScore"
        :colors="desiredColors"
        :colors2="individualColors"
      />

      <!-- Ficha Técnica -->
      <CandidateReportBack />
    </div>
    <div v-if="pdfUrl">
      <object :data="pdfUrl" type="application/pdf" width="100%" height="900px">
        <embed :src="pdfUrl" type="application/pdf" />
      </object>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/candidate-report-CULTURAL.scss";
</style>
