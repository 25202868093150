<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import LogoCfit from "@/assets/Logo.png";
import MinzerLogo from "@/assets/MinzerLogo.png";
import MinzerFooter from "@/assets/MinzerFooter.png";
import { DefinicionesArquetipos, companyData } from "@/data/data.js";

const obtenerTopArquetipos = (arquetiposPuntuacion) => {
  const entries = Object.entries(arquetiposPuntuacion);
  const topDos = entries
    .sort((a, b) => {
      if (b[1] !== a[1]) {
        return b[1] - a[1]; // Ordenar por puntuación de mayor a menor
      } else {
        return a[0].localeCompare(b[0]); // Desempatar alfabéticamente
      }
    })
    .slice(0, 2); // Tomar los dos primeros
  return topDos.map(([nombre]) => nombre); // Devolver solo los nombres de los arquetipos
};

const obtenerDefinicionArquetipo = (nombreArquetipo) => {
  const arquetipo = DefinicionesArquetipos.arquetipos.find(
    (arq) => arq.nombre === nombreArquetipo
  );
  return arquetipo ? arquetipo.definicion : "";
};

const route = useRoute();
const companyid = ref(route.params.companyid);
const pdfUrl = ref("");
const showReport = ref(true); // Se oculta el reporte cuando el PDF se genera
const reportData = ref(null);
const topArquetipos = ref([]);
const arquetipoTop1 = ref(null);
const arquetipoTop2 = ref(null);
const $loading = useLoading({});
let loadingLayer = null;

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const generatePDF = async () => {
  try {
    loadingLayer = $loading.show({ color: "#34495e", opacity: "0.7" });

    // Preload images
    await Promise.all([
      preloadImage(reportData.value.URL_Logo),
      preloadImage(LogoCfit),
    ]);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    const reportElement = document.querySelector("#candidate-report");

    if (reportElement && reportData.value) {
      // Ajustar la altura del contenido capturado a una página A4
      const canvas = await html2canvas(reportElement, {
        scale: 3,
        width: 794, // Tamaño estándar de ancho para A4 en píxeles
        height: reportElement.scrollHeight,
        windowWidth: 794,
        logging: true,
        useCORS: true,
        allowTaint: true,
      });
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [794, Math.min(1003, reportElement.scrollHeight)], // Ajusta la altura
        compress: true,
        precision: 32,
        putOnlyUsedFonts: true,
        hotfixes: ["px_scaling"],
      });

      pdf.addImage(
        canvas.toDataURL("image/png"), // Cambia a PNG para una mejor calidad
        "PNG",
        0,
        0,
        794, // Ancho de la página
        1023 // Altura de la página
      );

      const pdfBlob = pdf.output("blob");
      const blobURL = URL.createObjectURL(pdfBlob);

      setTimeout(() => {
        pdfUrl.value = blobURL;
        showReport.value = false; // Oculta el reporte original
      }, 1500); // Retardo de 1500 ms
      console.log("PDF generado:", blobURL);
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    loadingLayer.hide();
  }
};

onMounted(async () => {
  reportData.value = companyData[companyid.value];

  if (!reportData.value) {
    console.error("No company data found for ID:", companyid.value);
  } else {
    // Obtener los dos arquetipos con mayor puntuación
    topArquetipos.value = obtenerTopArquetipos(
      reportData.value.ArquetiposPuntuación
    );
    arquetipoTop1.value = {
      nombre: topArquetipos.value[0],
      definicion: obtenerDefinicionArquetipo(topArquetipos.value[0]),
    };
    arquetipoTop2.value = {
      nombre: topArquetipos.value[1],
      definicion: obtenerDefinicionArquetipo(topArquetipos.value[1]),
    };
    await Promise.all([
      preloadImage(reportData.value.URL_Logo),
      preloadImage(LogoCfit),
    ]);
    generatePDF();
  }
});
</script>

<template>
  <!-- Mostrar solo el PDF generado cuando showReport es false -->
  <div v-if="!showReport && pdfUrl" class="pdf-container">
    <object
      :data="pdfUrl"
      type="application/pdf"
      width="100%"
      height="900px"
    ></object>
  </div>

  <!-- Contenido para ser incluido en el PDF -->
  <div
    v-if="showReport && reportData"
    id="candidate-report"
    class="report-container"
  >
    <div class="pdf-body">
      <img :src="MinzerLogo" alt="MinzerLogo" class="minzer-logo" />
      <div class="header">
        <div class="imgNameVerify">
          <div class="company-logo2">
            <img
              :src="reportData.URL_Logo"
              alt="Logo Empresa"
              class="company-logo"
            />
          </div>
          <div class="nameAndVerify">
            <h1 class="company-name">{{ reportData.Nombre }}</h1>
            <p v-if="reportData.Verificada" class="company-verification-true">
              Verificada por Minzer®
            </p>
            <p v-else class="company-verification">No verificada por Minzer®</p>
          </div>
        </div>
        <p class="company-details">
          <span class="detail-icon">🏢</span> {{ reportData.Industria }}<br />
          <span class="detail-icon">📍</span> {{ reportData.País }}
        </p>
      </div>

      <div class="culture-section">
        <h2>Cultura predominante de {{ reportData.Nombre }}</h2>
        <div class="culture-description">
          <p>
            <span>{{ arquetipoTop1.nombre }}: </span>
            {{ arquetipoTop1.definicion }}
          </p>

          <p>
            <span>{{ arquetipoTop2.nombre }}: </span
            >{{ arquetipoTop2.definicion }}
          </p>
        </div>
        <h2 class="mt-5">
          Estos son los valores corporativos de {{ reportData.Nombre }}
        </h2>
      </div>

      <div class="fit-section">
        <div class="tags-container">
          <!-- Primera fila con los primeros 3 valores -->
          <div class="tag-row">
            <span
              v-for="(valor, index) in reportData.ValorCFAM.slice(0, 3)"
              :key="index"
              :class="`tag tag-${index + 1}`"
            >
              {{ valor }}
            </span>
          </div>

          <div class="tag-row">
            <span
              v-for="(valor, index) in reportData.ValorCFAM.slice(3, 6)"
              :key="index + 3"
              :class="`tag tag-${index + 4}`"
            >
              {{ valor }}
            </span>
          </div>
        </div>
      </div>

      <p class="values-note mb-8">
        Valores de <b>{{ reportData.Nombre }}</b> generados a partir de sus
        valores corporativos y <b>adaptados al modelo CFAM®</b>
      </p>

      <div class="more-info-section">
        <h2 class="mb-6">Más sobre {{ reportData.Nombre }}:</h2>
        <div class="d-flex justify-center">
          <button class="action-button primary">Solicitar empleo</button>
          <button class="action-button secondary">Descargar reporte</button>
        </div>
      </div>
    </div>
    <img :src="MinzerFooter" alt="MinzerFooter" class="MinzerFooter" />
  </div>
</template>

<style scoped>
.report-container {
  max-width: 794px;
}
.pdf-body {
  padding: 40px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.header {
  text-align: left;
  margin-bottom: 30px;
}
.imgNameVerify {
  display: flex;
  flex-direction: row;
}
.nameAndVerify {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.company-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background-color: #848484;
  border-radius: 10px;
  border: 3.5px;
}
.company-logo2 {
  width: 110px;
  height: 110px;
  object-fit: contain;
  background-color: #848484;
  border-radius: 10px;
  border: 3.5px;
}

.company-name {
  font-size: 44px;
  margin: 10px 0 5px;
}

.company-verification {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 10px;
}
.company-verification-true {
  font-size: 14px;
  margin-bottom: 10px;
  color: #00a7a5;
}

.company-details {
  font-size: 15px;
  color: #666;

  .detail-icon {
    margin-right: 6px;
  }
}

.culture-section,
.fit-section {
  margin-bottom: 30px;

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.culture-description {
  span {
    color: #00a0a0;

    font-size: 20px;
  }
}

.highlight {
  color: #00a0a0;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.tag-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  min-width: 100%;
}

.tag {
  padding: 8px 15px;
  border-radius: 7px;
  font-size: 14px;
  color: #ffffff;
  min-width: 30%;
  text-align: center;
}

.tag-1 {
  background-color: #a59de6;
}
.tag-2 {
  background-color: #ade2fd;
  color: #000000;
}
.tag-3 {
  background-color: #9ddfbf;
  color: #000000;
}
.tag-4 {
  background-color: #dcd9f1;
  color: #000000;
}
.tag-5 {
  background-color: #e2f9f9;
  color: #000000;
}
.tag-6 {
  background-color: #6f6c81;
}
.tag-7 {
  background-color: #f48fb1;
}
.tag-8 {
  background-color: #ffcc80;
}
.tag-9 {
  background-color: #ffab91;
}

.values-note {
  font-size: 14px;
  color: #666;
}

.more-info-section {
  text-align: left;

  .action-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 10px;

    &.primary {
      background-color: #00a0a0;
      color: #ffffff;
    }

    &.secondary {
      background-color: #e0f7fa;
      color: #00a0a0;
    }
  }
}

.MinzerFooter {
  width: 100%;
}

.report-footer {
  padding-bottom: 0;
  margin-bottom: 0;
}

.minzer-logo {
  width: 250px;
  position: absolute;
  top: 15px;
  right: 20px;
}

.pdf-container {
  text-align: center;

  h2 {
    margin-bottom: 15px;
  }

  object {
    border: 1px solid #ccc;
  }
}
</style>
