<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";

const store = useStore();

const Proximity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][73];
});
const OpennessToChange = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][74];
});
const Creativity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][75];
});
const Integrity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][76];
});
const Team = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][77];
});
const Cooperation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][78];
});
const Innovation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][79];
});
const FailureTolerance = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][80];
});
const Respect = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][81];
});
const Trust = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][82];
});
const Learning = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][83];
});
const Leadership = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][84];
});
const ResultsOrientation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][85];
});
const Formality = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][86];
});
const Excellence = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][87];
});
const Coordination = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][88];
});
const AnaliticFocus = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][89];
});
const Competitivity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][95];
});
const Estability = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][90];
});
const Transparency = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][104];
});
const Loyalty = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][101];
});
const Pragmatism = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][92];
});
const Responsibility = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][93];
});
const Ambition = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][91];
});
const Perseverance = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][94];
});
const Eficiency = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][96];
});
const Dinamysm = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][112];
});
const WellBeing = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][113];
});
const Generosity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][114];
});
const Independence = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][115];
});
const RiskFriendliness = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][116];
});
const Diversity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][117];
});
const Commitment = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][118];
});
const Boldness = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][119];
});
const Empathy = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][120];
});
const Sustainability = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][121];
});

const chartOptions = computed(() => ({
  chart: {
    height: 630,
    type: "radar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [
      Proximity.value,
      Dinamysm.value,
      ResultsOrientation.value,
      Formality.value,
      OpennessToChange.value,
      WellBeing.value,
      Generosity.value,
      Creativity.value,
      Excellence.value,
      Coordination.value,
      AnaliticFocus.value,
      Integrity.value,
      Team.value,
      Independence.value,
      Competitivity.value,
      Estability.value,
      Cooperation.value,
      Transparency.value,
      Loyalty.value,
      Innovation.value,
      Pragmatism.value,
      Responsibility.value,
      FailureTolerance.value,
      Respect.value,
      Trust.value,
      RiskFriendliness.value,
      Ambition.value,
      Perseverance.value,

      Learning.value,
      Diversity.value,
      Commitment.value,
      Boldness.value,
      Leadership.value,
      Eficiency.value,
      Empathy.value,
      Sustainability.value,
    ],
    labels: {
      show: true,
      style: {
        colors: [
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
        ],
        fontSize: "11px",
        fontFamily: "Arial",
      },
    },
  },

  yaxis: {
    show: false,
  },
  legend: {
    show: true,
    offsetY: -24,
    labels: {
      colors: "#34495e",
    },
    fontWeight: 600,
    fontSize: "9px",
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: props.colors,
      radius: 0,
      onClick: undefined,
    },
  },
  stroke: {
    width: 1,
    colors: props.colors,
  },
  markers: {
    size: 0,
  },
  fill: {
    colors: props.colors,
  },
}));

const props = defineProps({
  series: Object,
  height: String,
  width: String,
  colors: {
    type: Array,
    default: () => ["#02c071", "#34495e"],
  },
});
</script>

<template>
  <apexchart
    type="radar"
    :width="width"
    :height="height"
    :options="chartOptions"
    :series="series"
    class="mb-8"
  ></apexchart>
</template>

<style scoped></style>
