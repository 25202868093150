import API from "./API";

export default {
  loadCandidatesGeneralView(tokenProyecto, idioma) {
    let endpoint = "dashboard/" + tokenProyecto + "/" + idioma;
    return API().get(endpoint);
  },

  //CultureProfile
  loadCandidateCultureProfile(projectToken, candidateToken, leng) {
    let endpoint =
      "candidato_perfil/" + projectToken + "/" + candidateToken + "/" + leng;
    return API().get(endpoint);
  },

  //KPIS Engagement //añadir aquí mindset fit
  loadCandidateEngagement(projectToken, candidateToken, leng) {
    let endpoint =
      "candidato_kpi/" + projectToken + "/" + candidateToken + "/" + leng;
    return API().get(endpoint);
  },

  deleteCandidate(candidateToken) {
    let endpoint = "dashboard/archivar/" + candidateToken;
    return API().post(endpoint);
  },

  getCultureReportData(projectToken) {
    console.log("API call with projectToken:", projectToken); // Verifica el valor de projectToken aquí
    let endpoint = "dataRAW/" + projectToken;
    return API().get(endpoint);
  },

  getCandidateReportData(candidateToken, language) {
    let endpoint = "reporte/TT/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },

  //Liderazgo
  getCandidateLeadershipData(candidateToken, language) {
    let endpoint = "reporte/lider/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },

  //Parametrizar
  getParametrizationData(candidateToken, language) {
    let endpoint = "params/show/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },

  postParametrizeCandidate(name, data, proyecto_token) {
    let endpoint = "params";
    return API().post(endpoint, {
      name: name,
      data: data,
      proyecto_token: proyecto_token,
    });
  },
  UpdateParametrizeCandidate(name, data, id) {
    let endpoint = "params";
    return API().put(endpoint, {
      data: data,
      name: name,
      id: id,
    });
  },
  DeleteParametrizeCandidate(id) {
    let endpoint = "params";
    return API().delete(endpoint, {
      data: { id: id },
    });
  },

  getListofIDealParams(proyecto_token) {
    let endpoint = "params/list/" + proyecto_token;
    return API().get(endpoint);
  },
  postParamIdealtoCandidate(parameter_id, tokenCandidato) {
    let endpoint = "params/assignParam";
    return API().post(endpoint, {
      parameter_id,
      tokenCandidato,
    });
  },
};
