<script setup>
import horizontalLinesImage from "@/assets/report-images/horizontal-lines.png";
import CultureProfileChart from "@/components/CultureEvalComponents/ProfilesEvaluation/CandidateReport/CultureProfileChart.vue";
import DictionaryForCultureReport from "@/globals/DictionaryForCultureReport"; // Importar el diccionario
import { computed, defineProps } from "vue";
import { useStore } from "vuex"; // Importar useStore
import CultureReportPredominantValues from "./CultureReportPredominantValues.vue";

const props = defineProps({
  nameTitle: String,
  predominantCultures: {
    type: Array,
  },
  predominance: {
    type: Array,
  },
  mediaValues: {
    type: Array,
  },
  colors: {
    type: Array,
    default: () => ["#cccccc", "#999999"], // Colores por defecto
  },
  colors2: {
    type: Array,
    default: () => ["#cccccc", "#999999"], // Colores por defecto
  },
});

const store = useStore(); // Instancia de la tienda Vuex

const PageTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][3];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForCultureReport[currentLanguage][1];
});
</script>

<template>
  <!-- PAGE 1 araña con arquetipos-->
  <div id="executiveSummary" class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />
      <div class="main-container">
        <div>
          <div class="title">{{ PageTitle }}:</div>
          <div class="subtitle mt-2">
            <strong>{{ props.nameTitle }}</strong>
          </div>
        </div>

        <div class="charts">
          <div class="chart">
            <CultureProfileChart
              width="390px"
              height="390px"
              :series="props.predominantCultures"
              :colors="props.colors"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>

  <!-- PAGE 2 araña con valores-->
  <div class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />
      <div class="main-container">
        <div>
          <div class="title">{{ PageTitle }}:</div>
          <div class="subtitle mt-2">
            <strong>{{ props.nameTitle }}</strong>
          </div>
        </div>

        <div class="chart">
          <CultureReportPredominantValues
            :mediaValues="props.mediaValues"
            :colors="props.colors"
          />
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>

  <!-- PAGE 3 barras back and forth-->
</template>

<style lang="scss" scoped>
.page3-horizontal-lines {
  width: 312px;
  position: absolute;
  margin-top: 0px;
  right: 0px;
}

.charts {
  display: flex;
  gap: 110px;
  justify-content: space-around;

  .chart {
    min-width: 200px;
  }
}
</style>
