<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";

import piecesImageOK from "@/assets/report-images/pieces-ok.png";
import piecesImageKO from "@/assets/report-images/pieces-ko.png";
import dotsImage from "@/assets/report-images/dots.png";
import CandidateReportArchetypeAffinityTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportArchetypeAffinityTable.vue";
import CandidateReportCultureProfileChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCultureProfileChart.vue";
import DictionaryForReport from "@/globals/DictionaryForReport";

defineProps({
  reportData: Object,
});

const store = useStore();

const MentOF = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][10];
});
const basedOn = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][17];
});
const tableReveal = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][18];
});
const PredMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][19];
});
const Indent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][20];
});
const highFit = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][21];
});
const lowFit = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][22];
});
const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="reportMindsetOf" class="page">
    <div class="page-body">
      <img :src="dotsImage" class="page1-dots" />
      <div class="main-container">
        <div class="title">
          {{ MentOF }} <strong>{{ reportData.name }}</strong
          >:
        </div>
        <p class="small-dark-green-field" v-html="basedOn"></p>

        <div class="chart">
          <CandidateReportCultureProfileChart
            height="260px"
            v-bind:series="reportData.archValuesChartSeries"
          />
        </div>

        <p class="small-dark-green-field" v-html="tableReveal"></p>

        <div class="chart">
          <CandidateReportArchetypeAffinityTable
            v-bind:reportData="reportData"
            height="240px"
          />
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>

  <div id="reportMindsetOf2" class="page">
    <div class="page-body">
      <img :src="dotsImage" class="page2-dots" />
      <div class="main-container">
        <div class="subtitle">
          {{ PredMent }}
          <strong>{{ reportData.archMax1 }}</strong> &
          <strong>{{ reportData.archMax2 }}</strong>
        </div>
        <p class="small-dark-green-field">
          <strong>{{ reportData.archMax1 }}</strong
          >: {{ reportData.archMax1Text }}
        </p>
        <p class="small-dark-green-field">
          <strong>{{ reportData.archMax2 }}</strong
          >: {{ reportData.archMax2Text }}
        </p>
        <p class="small-dark-green-field" v-html="Indent"></p>

        <table>
          <tr>
            <td class="regular-cell green center bold">
              <img :src="piecesImageOK" class="pieces-image" />
              {{ highFit }}
            </td>
            <td class="regular-cell yellow center bold">
              <img :src="piecesImageKO" class="pieces-image" />
              {{ lowFit }}
            </td>
          </tr>
          <tr>
            <td class="regular-cell">
              <ul>
                <li
                  v-for="(value, key) in reportData.archMax1AreaProcessed"
                  :key="key"
                >
                  {{ value }}
                </li>
              </ul>
            </td>
            <td class="regular-cell">
              <ul>
                <li
                  v-for="(value, key) in reportData.archMin1AreaProcessed"
                  :key="key"
                >
                  {{ value }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="regular-cell">
              <ul>
                <li
                  v-for="(value, key) in reportData.archMax2AreaProcessed"
                  :key="key"
                >
                  {{ value }}
                </li>
              </ul>
            </td>
            <td class="regular-cell">
              <ul>
                <li
                  v-for="(value, key) in reportData.archMin2AreaProcessed"
                  :key="key"
                >
                  {{ value }}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.chart {
  width: 260px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
}

.pieces-image {
  display: block;
  width: 36px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
}

.page1-dots {
  display: block;
  width: 183px;
  position: absolute;
  margin-top: 460px;
  right: 0px;
}

.page2-dots {
  display: block;
  width: 183px;
  position: absolute;
  margin-top: 900px;
  left: 0px;
}
</style>
